.login-form input:not([type='checkbox']),
.login-form select,
.login-form .referral-inp button{
    border-bottom-right-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.login-form input[type='checkbox']{
    width: 30px;
    height: 20px;
}
.login-form .referral-inp button{
    z-index: 0;
}
.login-form .select-cont{
    width: calc(100% - 38px);
}
.login-form .input-group-text{
    width: 42px;
    padding: 0px;
    justify-content: center;
    margin: 0px;
}
.login-form i{
    font-size: 18px;
}
.login-form .form-floating{
    width: calc(100% - 38px);
}
.login-form .form-floating input{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.login-form .form-floating>.form-control, .login-form .form-floating>.form-select{
    height: calc(2.7rem + 2px);
    line-height: 1;
}
.login-form .form-floating>label{
    padding: 0.6rem .75rem;
    color: #919191;
    white-space: nowrap;
    width: -webkit-fill-available;
    text-overflow: ellipsis;
    overflow: hidden;
}
/* .login-form .select-float > div:first-child{
    position: absolute;
    top: 14px;
} */
.login-form .inp-whatsapp{
    width: calc(100% - 147px);
}
.login-form .inp-password{
    width: calc(100% - 108px);
}
.login-form .inp-referral{
    width: calc(100% - 165px);
}
.login-form .card{
    box-shadow: 0px 13px 12px -6px rgb(100 97 97 / 66%);
}
.payment-plan .content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 308px;
}
.login-form .referral-inp input{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.address-modl .input-group-text{
    width: 40px;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    /* border: 1px solid; */
    border-radius: 0.25rem;
    /* color: #768192; */
    color: #0f326b;
    background-color: #ebedef;
    /* border-color: #d8dbe0; */
    /* border-color: #0f326b; */
    border: 1px solid #cccccc;
}
.custom-form .input-group-text{
    margin-right: -3px;
}
/* .custom-form .fa-lock{
    font-size: 22px;
} */
.custom-form .fa-user{
    font-size: 20px;
}
.link{
    margin-top: 30px;
    font-size: 14px;
}
.link-highlight{
    color: blueviolet;
}
.link-highlight:hover{
    color: #D10024;
}
.input-info{
    font-size: 12px;
    float: right;
    color: #768192;
    font-weight: 500;
}
.icon-eye{
    margin: 12px;
    cursor: pointer;
}
.image-preview{
    width: 150px;
    height: 150px;
    border-radius: 15px;
    background: #ddd;
}
.img-wrap {
    position: relative;
    width: 150px;
    height: 150px;
    /* background: #ddd; */
    /* border-radius: 15px; */
}
.img-wrap .delete {
    position: absolute;
    top: -4px;
    right: 0px;
    z-index: 100;
    background: #d10024;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    line-height: 1.3;
    color: white;
    font-size: 20px;
}
.scroll-150{
    height: 150px;
    overflow: auto;
}
.login-form em{
    color: red;
}
.login-form .invalid-inp{
    border-color: #dc3545;
}
/*--------------------------------------------------------------
# Payment Paln
--------------------------------------------------------------*/
.payment-plan .card:hover{
    border-color: var(--theme-blue);
    filter: drop-shadow(0 0 12px rgba(29, 30, 32, .16));
}
.payment-plan .skip-text{
    text-decoration: underline;
    text-align: right;
    margin-bottom: 0px;
    color: grey;
}
.pay-modal .detail,
.pay-modal .pay-info{
    background: #f4f5ff;
    /* display: flex; */
    padding: 16px;
    border-radius: 8px;
}
.pay-modal .detail{
    display: flex;
}
.pay-modal .detail .desc h5{
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 0px;
}
.pay-modal .detail .desc p{
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #6d7081;
    margin-bottom: 8px;
}
.pay-modal .detail .desc span{
    font-size: 14px;
}
.pay-modal .detail .price{
    background: #ffffff;
    border-radius: 8px;
    padding: 20px 16px;
/* }
.pay-modal .detail .price{ */
    /* margin-bottom: 0px; */
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    color: #1d1e20;
    white-space: nowrap;
}
.pay-modal .detail .price small{
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
}
.pay-modal .term{
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 16px;
}
.pay-modal .term a{
    color: #673de6;
    font-weight: 600;
}
.pay-modal table th,
.pay-modal table td{
    font-size: 14px;
    padding: 4px;
    font-weight: 500;
    /* border-width: 0px; */
}
.pay-modal .note{
    padding: 8px 16px;
    background: #def4f0;
}
.pay-modal .note p{
    font-size: 13px;
}
.modal-custom-wid{
    max-width: none !important;
    width: 600px;
}
@media screen and (max-width: 600px){
    .modal-custom-wid{
        max-width: none !important;
        width: 100%;
    }
    .pay-modal .detail .price {
        padding: 10px 10px;
        margin-left: 12px;
    /* }
    .pay-modal .detail .price p { */
        font-size: 22px;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .payment-plan .content {
        min-height: 430px;
    }
}