/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root{
  --theme-red: #f82249;
/*  --theme-red: #a53c34;*/
/*  --theme-earth:  #973a1e;*/
/*  --theme-blue: #26529e;*/
    --theme-blue: #0455AA;
    --theme-blue2: #0f326b;
    --blue: #007bff;
    --blue-3: #1454a2;
    --blue-4: #0a376d;
    --theme-primary-blue: rgb(0, 86, 171);
    --theme-dark-blue: rgb(10, 55, 109);
    --theme-dark-grey: rgb(78, 90, 91);
    --theme-dark-grey2: rgb(128, 128, 127);
    --theme-purple: #942093;
}

body {
  background: #fff;
/*  background: #ebecf0;*/
  color: #2f3138;
  font-family: "Open Sans", sans-serif;
/*  line-height: 1.8;*/
/*  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";*/
  /* font-weight: 400; */
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  /* font-size: 17px; */
  line-height: 1.5;
}

a {
  /* color: var(--theme-red); */
  color: #1e72bd;
  text-decoration: none;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  /* color: #f8234a; */
  color: #2f5597;
  outline: none;
  text-decoration: none;
}

li {
  list-style: none;
}
ol, ul{
  padding: 0px;
}
.li-sty-none li{
  list-style: none !important;
}
.li-sty-dot li{
  list-style-type: disc;
}
p {
  padding: 0;
  /* margin: 0 0 30px 0; */
  font-size: 16px;
  margin-bottom: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: #0e1b4d;
}
h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 30px;
}

section{
  padding: 60px 0 0px 0;
}
.mt-60{
  margin-top: 60px;
}
.main-page {
  margin-top: 70px;
}
hr {
  background-color: rgb(0 0 0 / 60%);
  border: 0;
  height: 1px;
  margin-bottom: 40px;
  margin-top: 40px;
}
.color-blue{
  color: var(--theme-primary-blue);
}
.color-white{
  color: #fff;
}
.color-red{
  color: red;
}
.color-black{
  color: black;
}
.text-green{
  color: #4caf50;
}
.color-grey2{
  color: var(--theme-dark-grey2);
}
/* .bg-drk-blue{
  background: #0d0d92;
}
.bg-lgt-blue{
  background: #5050ef;
} */
.bg-color-purple{
  background: var(--theme-purple);
}
.bg-color-lg-purple{
  background: rgb(142, 101, 182);
}
.bg-color-purple1{
  background: rgb(100, 34, 184);
}
.bg-color-purple2{
  background: rgb(198, 33, 136);
}
.bg-color-blue2{
  background: rgb(146, 125, 246);
}
.bg-color-purple3{
  background: rgb(195, 0, 205);
}
.bg-color-ligblue1{
  background: #f0f8ff;
}
.bg-lgt-green{
  background: #8bc34a;
}
/* .color-d-blue{
  color: var(--theme-dark-blue);
}
.color-grey{
  color: var(--theme-dark-grey);
} */
.bg-color-blue{
  background: var(--theme-primary-blue) !important;
}
.bg-color-d-blue{
  background: var(--theme-dark-blue) !important;
}
.bg-color-grey{
  background: var(--theme-dark-grey) !important;
}
.bg-color-grey2{
  background: var(--theme-dark-grey2) !important;
}
.trans-capital{
  text-transform: capitalize;
}
.font-20{
  font-size: 20px;
}
.font-25{
  font-size: 25px;
}
.fs-12p{
  font-size: 12px;
}
.f-weight-700{
  font-weight: 700;
}
.mt-50
{
  margin-top: 50px;
}
.w-200p{
  width: 200px;
}
.w-140p{
  width: 140px;
}
.w-150p{
  width: 150px;
}
.w-250p{
  width: 250px;
}
.w-350p{
  width: 350px;
}
.w-100p{
  width: 100px;
}
.w-110p{
  width: 110px;
}
.w-90p{
  width: 90px;
}
.w-12vw{
  width: 12vw;
}
.w-13vw{
  width: 13vw;
}
.w-30vw{
  /* width: calc(100vw - 2px); */
  width:30vw;
}
.h-100p{
  height: 100px;
}
.strike{
  text-decoration: line-through;
}
.same-row{
  flex-flow: row wrap;
  display: flex;
}
.btnn-disabled{
  color: #fff !important;
  background-color: #80807f !important;
  border-color: #3f3f40 !important;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
em.required{
  color: red;
}
/* select:invalid { color: gray; }
select:not(:valid) {
  color: #999;
} */
 .highlight{
  color: red;
  font-style: italic;
 }
/* Prelaoder */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  /* background: #fff url("../images/preloader.svg") no-repeat center center; */
}
.screen-overlay{
  opacity:    0.5; 
  background: #000; 
  width:      100%;
  height:     100%; 
  z-index:    99999;
  top:        0; 
  left:       0; 
  position:   fixed;
}
.overlay-main{
  opacity: 0.9;
  position: relative;
}
.div-overlay-center{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
}
.div-overlay-cen-visible{
  z-index: 99999;
  position: fixed;
  /* width: 35vw; */
}
.div-overlay-cen-visible .head-port{
  width: 400px;
}
/* #bodyOverlay svg{
  display: none;
} */
#bodyOverlay{
  display: none;
}
.screen-overlay img{
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;
}
.notifications-component{
  z-index: 99999;
}
/*--------------------------------------------------------------
# Input
--------------------------------------------------------------*/
/* input[disabled], select[disabled], textarea[disabled], input[readonly], select[readonly], textarea[readonly] { */
input[disabled], input[readonly] {
  background-color: #EEE !important;
  cursor: not-allowed;
}
/*--------------------------------------------------------------
# Head-Title
--------------------------------------------------------------*/
/*. h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}*/
.heading-subtext{
  font-size: 12px;
  /* margin-top: 9px; */
  color: var(--blue-3);
}
.heading-grey, .sus-heading-grey{
  text-shadow: rgba(0, 0, 0, 0.325) 0px 3.12941px 13.3px;
  font-weight: bold;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.heading-grey{
  font-size: 4.6vw !important;
  line-height: calc(4.6vw + 4px);
}
.sus-heading-grey{
  font-size: 2.8vw !important;
  line-height: calc(2.8vw + 4px);
}
.title{
  position: relative;
  color: #0f326b;
/*  text-transform: uppercase;*/
  text-align: center;
  margin-bottom: 50px;
/*  line-height: 11px;*/
/*  font-weight: 700;*/
/*  font-size: 30px;*/
}
.title:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.title:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}
.header-image{
  width: 100%;
}
.header-bottom{
  box-shadow: 0 2px 10px -2px rgba(108, 108, 108, 0.66);
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer-main{
  padding: 0px 0 16px 0;
  /* padding: 10px 0 25px 0; */
  /* padding: 110px 0 25px 0; */
  /* -webkit-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%); */
  /* clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%); */
  background-color: #095cb8;
  color: #fefefe;
  margin-top: 30px;
}
.footer-top .footer-logo{
  background: white;
  width: 150px;
  padding: 5px;
  border-radius: 6px;
  margin: 0px auto;
  margin-bottom: 15px;
}
/* .footer-top img{
  width: 100px;
} */
.footer-bottom{
  background-color: var(--theme-blue2);
}
.footer-bottom p{
  margin-bottom: 0px;
  padding: 12px 8px;
}
.footer-bottom p a{
 color: white;
}
.footer-main p, .footer-main h5 {
  color: white;
  margin-bottom: 15px;
}
.footer-link{
  margin: 0px;
  padding: 0px 0px 0px 5px;
}
.footer-main .footer-link li i {
  font-size: 14px;
  padding-right: 12px;
  color: #fff;
  /* color: #73f3c8; */
}
.footer-main li a {
  color: white;
}
.footer-main h5 {
  margin-top: 20px;
}
.footer-main li a:hover, footer li a:active{
  color: #73f3c8;
}
@media screen and (min-width: 576px){
  h5 {
    font-size: 22px;
    line-height: 30px;
  }
}
.back-to-top{
  /* background-color: rgba(30,114,189,0.35); */
  /* color: #ffffff; */
  border: 1px solid #ffffff77;
  position: fixed;
  bottom: -30%;
  /* right: 5%; */
  left: 2%;
  z-index: 9;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #1e72bd;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  bottom: 2%;
  opacity: 0;
  visibility: hidden;
}
.back-to-top:active,.back-to-top:visited{
  color: white !important;
}
.back-top-active{
  opacity: 1;
  visibility: visible;
}
/*--------------------------------------------------------------
# Menu
--------------------------------------------------------------*/
.primary-menu .menu-area .logo {
  padding: 12px 0;
  outline: none;
  position: relative;
}
.primary-menu .menu-area ul.main-menu {
  list-style: none;
  margin: 0;
  justify-content: center;
}
.primary-menu .menu-area ul.main-menu li {
  padding: 0;
  position: relative;
}
.primary-menu .menu-area ul.main-menu li:last-child a {
  margin-right: 0;
}
.primary-menu .menu-area ul.main-menu li a {
/*  color: #181818;*/
  color: #0f326b;
/*  padding: 20px 10px;*/
  padding: 12px 10px;
  display: inline-block;
  margin: 0 10px;
  font-weight: 400;
  position: relative;
/*  text-transform: capitalize;*/
  outline: none;
  font-size: 18px;
  /*  font-family: "Frank Ruhl Libre", serif;*/
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  /* font-size: 17px; */
  font-size: 16px;
}
.primary-menu .menu-area ul.main-menu li a.dd-icon-down {
  padding-right: 30px;
}
.primary-menu .menu-area ul.main-menu li a.dd-icon-down::after {
  right: 10px;
}
.primary-menu .menu-area ul.main-menu li a.active,
.primary-menu .menu-area ul.main-menu li a:hover,
.primary-menu .menu-area ul.main-menu li.active > a {
/*  color: var(--theme-green);*/
  color: #0455aa;
}
.primary-menu .menu-area ul.main-menu li a.active::after,
.primary-menu .menu-area ul.main-menu li a:hover::after,
.primary-menu .menu-area ul.main-menu li.active > a::after{
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: 4px;
  height: 2px;
  background-color: #0455AA;
  width: 70px;
  transition: all .2s;
}
.primary-menu .menu-area ul.main-menu .dropdown ul{
  border-top: 3px solid #0455aa;
  box-shadow: 0 2px 5px #22222299;
  top: 44px;
}
.primary-menu .menu-area ul.main-menu .dropdown ul a.active::after,
.primary-menu .menu-area ul.main-menu .dropdown ul a:hover::after{
  content: none;
}
.primary-menu .menu-area ul.main-menu .dropdown ul{
  padding-left: 0px;
  margin-top: -5px;
}
.primary-menu .menu-area ul.main-menu .dropdown li{
  padding-left: 10px;
}
.primary-menu .menu-area ul.main-menu .dropdown li a{
  text-transform: none;
}
.primary-menu .menu-area ul.main-menu li:hover > a {
  text-decoration: none;
}
.primary-menu .menu-area ul.main-menu li ul {
  margin: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  /* width: 270px; */
  width: 280px;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.primary-menu .menu-area ul.main-menu li ul li {
  position: relative;
  list-style: none;
  float: none;
  padding-left: 30px;
  border-bottom: 1px solid #ededed;
}
.primary-menu .menu-area ul.main-menu li ul li:last-child {
  border-bottom: none;
}
.primary-menu .menu-area ul.main-menu li ul li a {
  padding: 11px 0;
  color: #505050;
  margin: 0;
  display: block;
  text-align: left;
  padding-left: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.primary-menu .menu-area ul.main-menu li ul li a:hover,
.primary-menu .drop-custom a:hover{
  background-color: #fff;
  padding-left: 8px !important;
}
/* .drop-custom div a{
  padding: 11px 0;
} */
.primary-menu .menu-area ul.main-menu li ul li ul {
  left: 100%;
  top: -5px;
}
.primary-menu .menu-area ul.main-menu li:hover > ul {
  z-index: 1;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  transform: translateY(3px);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}
.primary-menu .menu-area ul.main-menu ul li.active a {
  color: #ff7555;
}
.primary-menu.menu-fixed {
  position: fixed;
  background: #fff;
  top: 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1) !important;
}
.header-section .header-bottom.menu-fixed .primary-menu {
  box-shadow: none;
  padding: 0;
}
.header-section.style-2 .primary-menu {
  box-shadow: none;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header-section.style-5 .primary-menu .menu-area .logo {
    width: 120px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header-section.style-5 .menu-fixed .primary-menu .menu-area .logo {
    width: 200px;
  }
}
.primary-menu {
  z-index: 11;
  top: 50px;
  width: 100%;
  text-align: center;
  display: block;
  background: transparent;
  /*box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);*/
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu {
  position: fixed;
  /* top: 100px; */
  left: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
  transform: scaleY(0);
  transform-origin: top;
  height: 100vh;
  overflow-y: scroll;
  padding: 0 30px;
  padding-bottom: 60px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.primary-menu .menu-area ul.main-menu li img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
@media screen and (max-width: 1200px){
  .primary-menu .menu-area ul.main-menu li a{
    font-size: 12px;
  }
}
/*--------------------------------------------------------------
# Menu-Mobile
--------------------------------------------------------------*/
.menu .mobile-menu-area .mobile-menu-area-inner ul {
  text-align: center;
  margin: 0;
  padding: 30px 0;
  padding-top: 60px;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li {
  list-style: none;
  padding: 0;
  position: relative;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li.open > a.dd-icon-down::after {
  transform: rotate(180deg) translateY(10px);
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li:last-child > a {
  border-bottom: none;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li a {
  display: block !important;
  padding: 10px 0;
  color: #181818;
  text-transform: capitalize;
  margin: 2px 0;
  text-align: left;
  /*border-bottom: 1px solid #ededed;*/
  outline: none;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li a::after {
  right: 0;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li a:hover,
.menu .mobile-menu-area .mobile-menu-area-inner ul li a.active
{
  color: #30a27c;
  /* color: #5d9913; */
}
/* .menu .mobile-menu-area .mobile-menu-area-inner ul li a.active {
  color: #5d9913;
} */
.menu .mobile-menu-area .mobile-menu-area-inner ul li ul {
  display: none;
  margin: 0;
  padding: 0;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li ul li {
  padding-left: 20px;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li ul li a {
  margin: 0;
  text-transform: capitalize;
  outline: none;
  position: relative;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li ul li ul li ul li a {
  padding-left: 60px;
}
.menu .mobile-menu-area .mobile-menu-area-inner ul li ul li ul li ul li ul li a {
  padding-left: 70px;
}
.m-menu .dropdown .dropdown-active{
  display: block !important;
}
.mobile-header {
  /*padding: 10px 15px;*/
/*  padding: 10px 15px 25px;*/
/*padding: 5px 15px 0px;*/
    padding: 10px 15px 15px;
/*  background: #eff4f8;*/
  position: fixed;
  top: 0;
  width: 100%;
  /* z-index: 111; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  box-shadow: 0 2px 10px -2px rgba(108, 108, 108, 0.66);
  z-index: 999;
}
.mobile-header .logo{
  position: relative;
}
.mobile-header .tagline{
/*  top: 27px;*/
  color: var(--theme-blue2);
  margin-bottom: 0px;
}
.mobile-header .header-bar {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.mobile-header .header-bar span {
/*  background: #5d9913;*/
  width: 100%;
  height: 3px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
  transform-origin: center;
  transition: all 0.3s ease;
  background: var(--theme-blue);
}
.mobile-header .header-bar span:nth-child(1) {
  margin-top: -9px;
}
.mobile-header .header-bar span:nth-child(2) {
  opacity: 1;
}
.mobile-header .header-bar span:nth-child(3) {
  margin-top: 7px;
}
.mobile-header .header-bar.close span:nth-child(1) {
  transform: rotate(-45deg);
  margin-top: -1px;
}
.mobile-header .header-bar.close span:nth-child(2) {
  opacity: 0;
}
.mobile-header .header-bar.close span:nth-child(3) {
  transform: rotate(45deg);
  margin-top: -1px;
}
.menu.open {
    transform: scaleY(1);
    z-index: 999;
}
/*--------------------------------------------------------------
# Partner
--------------------------------------------------------------*/
.partner-section a{
  display: inline-block;
}
@media screen and (max-width: 767px){
  #iffooterpartners{
    padding: 28px;
  }
}
/*--------------------------------------------------------------
# Slick
--------------------------------------------------------------*/
.slick-next:before, .slick-prev:before{
  color: #0f326b !important;
}
/*--------------------------------------------------------------
# Accordian
--------------------------------------------------------------*/
.portfolio-edit .accordion-header button{
  background-color: #0052a8;
  color: #fff;
}
/*--------------------------------------------------------------
# Cropper
--------------------------------------------------------------*/
.cropper-view-box{
  outline: 2px solid #39f !important;
}
.cropper-modal{
  border: 1px solid black;
  border-radius: 3px;
  background-color: white !important;
}
.cropper-preview-img{
  width: 200px !important;
  height: 200px !important;
}
/*--------------------------------------------------------------
# Rating
--------------------------------------------------------------*/
.rating-content{
  width: fit-content;
}
.rating button{
  width: 30px;
  height: 30px;
  /* background: red; */
  /* justify-content: center; */
  /* align-items: center; */
  /* align-content: space-around; */
  text-align: center;
  vertical-align: middle;
  /* margin-bottom: 0px; */
  margin-right: 12px;
  margin-left: 3px;
  margin-top: 5px;
  padding: 0px;
  color: black;
}
.rating button.red{
  background: #ff2600;
}
.rating button.yellow{
  background: #ffc000;
}
.rating button.green{
  background: #00b050;
}
.rating button:hover,
.rating button.active{
  /* padding: 2px; */
  width: 32px;
  height: 32px;
  border: 1px solid black;
  color: white;
  font-weight: 600;
  font-size: 18px;
}
/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/
.modal-title{
  font-size: 18px;
  font-weight: 600;
}
.mod-custom,
.mod-custom .modal-dialog{
  z-index: 9999;
}
.mod-custom form{
  max-height: 75vh;
  overflow-x: auto;
}
.mod-custom .modal-title{
  font-size: 18px;
  font-weight: 600;
}
.mod-custom .modal-header{
  padding: 8px 12px;
}
.mod-custom .form-label{
  font-size: 15px;
  margin-bottom: 0px;
  color: var(--theme-blue);
}
/* .mod-custom .form-control{
  border: 1px solid black;
} */
.mod-custom fieldset{
  border: 1px solid #8ea9d5 !important;
  /* margin: 25px 0px; */
  background-color: #f0f2f4;
  border-radius: 3px;
  padding: 29px 18px 18px;
  margin-top: 12px;
  position: relative;
}
.mod-custom legend{
  font-size: 15px;
  font-weight: 600;
  border: 0;
  background: #fff;
  width: auto;
  transform: translateY(-50%);
  position: absolute;
  top: 0; left: 1em;
  padding: 0 .5em;
}
.mod-custom .form-control::placeholder{
  font-size: 14px !important;
  color: #919191 !important;
}
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
.text-black{
  color: black;
  font-weight: normal;
}
.scroll-div{
  scroll-margin: 50px;
}
/*--------------------------------------------------------------
# React Select
--------------------------------------------------------------*/
.reactCSel__multi-value__label .hideSpan{
  display: none;
}
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
@keyframes circleGlow {
  from {
    box-shadow: 0 0 5px -5px #007bffa8;
  }
  to {
    box-shadow: 0 0 5px 5px #007bffa8;
  }
}
.btn-initiate a:visited,
.btn-initiate a{
    color: white;
}
.btn-glow{
  animation: circleGlow 1s infinite alternate;
}
/*--------------------------------------------------------------
# Image Deletion Interface
--------------------------------------------------------------*/
.img-edit-container{
  overflow: auto;
  padding: 20px 0px 0px;
  /* align-items: center; */
  display: flex;
  /* position: relative; */
  column-gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}
.img-edit-container .delete{
  z-index: 99;
  box-shadow: 0px 13px 12px -6px rgb(100 97 97 / 66%);
  right: -10px;
  top: -10px;
  position: absolute;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  padding: 7px;
  background: red;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* vertical-align: middle; */
  display: flex;
}
.img-edit-container .delete-img{
  opacity: 0.2;
}
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media screen and (max-width: 576px){
  .mod-custom .modal-title {
    font-size: 14px;
  }
  .table-res tr{
    display: grid;
  }
  .table-res tr td{
    padding-top: 0;
    width: 100% !important;
  }
  .table-res tr th{
    padding-bottom: 0;
    width: 100% !important;
  }
  .table-res tr:not(:last-child){
    border-bottom-width: 0;
  }
}
/*--------------------------------------------------------------
# Chat Loader
--------------------------------------------------------------*/
.chat-loader .loader-container {
  background-color: #f4f7f9;
  border-radius: 10px;
  max-width: 215px;
  /* padding: 15px; */
  text-align: left;
  width: fit-content;
  padding: 20px 20px;
  display: flex;
}
.chat-loader .loader-dots {
  animation: ChatLoader .5s ease infinite alternate;
  background: grey;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}
.chat-loader .loader-dots:first-child {
  animation-delay: .2s;
}
.chat-loader .loader-dots:nth-child(2) {
  animation-delay: .3s;
}
.chat-loader .loader-dots:nth-child(3) {
  animation-delay: .4s;
}
@keyframes ChatLoader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}
/*--------------------------------------------------------------
# Privacy Policy
--------------------------------------------------------------*/
.privacy-detail h2 {
  /* font-size: 15px; */
  font-size: 19px;
  /* color: var(--blue); */
  color: var(--theme-blue);
  margin-bottom: 1px;
  font-weight: 600;
}
.privacy-detail ol li ol li ol,
.terms-detail ol li ol {
  list-style: none;
  margin-left: -16px;
}

.privacy-detail ol li ol li ol>li,
.terms-detail ol li ol>li {
  display: table-row;
}

.privacy-detail ol li ol li ol>li:before,
.terms-detail ol li ol>li:before {
  content: attr(value)".";
  display: table-cell;
  padding-right: 5px;
}
/* .privacy-detail li ol li ol {
  counter-reset: item;
}
.privacy-detail li ol li ol li {
  display: block;
  position: relative;
} */
/* .privacy-detail li ol li ol > li:before {
  content: counters(item, ".") " ";
    content: counters(item, ".")".";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 10px; 
} */
/* .privacy-detail ol li {display:block;}
.privacy-detail ol > li:first-child {counter-reset: item;}
.privacy-detail ol > li {counter-increment: item; position: relative;}
.privacy-detail ol > li:before {content:counters(item, ".") ". "; position: absolute; margin-right: 100%; right: 10px;}  */

/*--------------------------------------------------------------
# Custom Popup
--------------------------------------------------------------*/
.custom-toast{
  left: 50%;
  max-width: 90vw;
  position: fixed;
  transform: translateX(-50%);
  z-index: 6;
  bottom: 30px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: rgba(25, 25, 25, .6);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px 20px;
  width: -moz-max-content;
  width: max-content;
}
.custom-toast .stu-popp{
  padding: 30px 35px 0px 0px;
}
.custom-toast .btn-rmv{
  text-align: end;
  padding: 0px;
  background: transparent !important;
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #c6c8c7;
}
.custom-toast .stu-img{
  background: white;
  border-radius: 50%;
}
.custom-toast .btn{
  background: white;
  border-radius: 50px;
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
}
.custom-toast .btn:focus{
  color: #22262a;
}
.custom-toast .btn:hover,
.custom-toast .btn.active{
  background-color: var(--theme-blue) !important;
  color: white;
}
.custom-toast .btn-rmv:hover{
  background-color: transparent !important;
  color: white;
}
.custom-toast .btn:disabled{
  opacity: 1;
}
/*--------------------------------------------------------------
# Blur Screen
--------------------------------------------------------------*/
.blur-screen{
  opacity: 0.5;
  filter: blur(1px);
  /* filter: url(blur.svg#gaussian_blur); */
  -webkit-filter: blur(1px);
}
.blur-screen img{
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
/*--------------------------------------------------------------
# Text Display Lines
--------------------------------------------------------------*/
.one-line,
.three-line{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.one-line{
  -webkit-line-clamp: 1;
}
.three-line{
  -webkit-line-clamp: 3;
}
@media screen and (min-width: 768px){
  .mt-up  {
    /* top: -40px;
    position: relative; */
    margin-top: -40px;
  }
}